import { ref } from 'vue'

/** @type {import('vue').Ref<{'' | 'you' | 'company'}>} */
const activeSubNav = ref('')

/** @param {'' | 'you' | 'company'} subNav */
function setActiveSubNav(subNav) {
  activeSubNav.value = subNav
}

export default function useActiveSubNav() {
  return {
    activeSubNav,
    setActiveSubNav,
  }
}

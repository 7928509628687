<template>
  <Stack gap="sm">
    <PageLink to="/" style="text-decoration: none" @click="setActiveSubNav('')">
      <Row gap="lg">
        <IconCaretLeft />
        <b>{{ title }}</b>
      </Row>
    </PageLink>
    <div class="links">
      <Stack gap="xs">
        <slot />
      </Stack>
    </div>
  </Stack>
</template>

<script setup>
import { Stack } from '@justworkshr/alma'
import { Row } from '@justworkshr/alma'
import PageLink from '@/components/PageLink.vue'
import useActiveSubNav from '@/use/useActiveSubNav.js'
import IconCaretLeft from '~icons/ph/caret-left'

defineProps({
  title: { type: String, required: true },
})

const { setActiveSubNav } = useActiveSubNav()
</script>

<style scoped>
.links {
  padding-left: var(--space-lg);
}
</style>

<template>
  <SubNav :title="$t('You')">
    <WorkspaceLink to="/account/settings/basic-information" name="profile">
      {{ $t('Profile') }}
    </WorkspaceLink>
    <WorkspaceLink v-if="memberTypesForFeature.has_compensation.has(memberType)" to="/account/paystubs" name="pay">
      {{ $t('Pay') }}
    </WorkspaceLink>
    <WorkspaceLink v-if="showTimeTrackingURL" :to="timeTrackingURL" name="timecards">
      {{ $t('Timecards') }}
    </WorkspaceLink>
    <WorkspaceLink
      v-if="memberTypesForFeature.pto.has(memberType) && !isTerminated"
      to="/account/time-off"
      name="time-off"
    >
      {{ $t('Time off') }}
    </WorkspaceLink>
    <WorkspaceLink v-if="canSeeBenefitsLink" :to="benefitsSSOLink" :title="benefitsSSOLink" name="benefits">
      {{ $t('Benefits') }}
    </WorkspaceLink>
    <WorkspaceLink to="/account/documents" name="documents">
      {{ $t('Documents') }}
    </WorkspaceLink>
    <WorkspaceLink to="/account/settings/settings" name="preferences">
      {{ $t('Settings') }}
    </WorkspaceLink>
  </SubNav>
</template>

<script setup>
import { computed } from 'vue'
import WorkspaceLink from '@/components/WorkspaceLink.vue'
import { getTimeTrackingURL } from '@/data/integrations.js'
import { memberTypesForFeature } from '@/data/memberTypes.js'
import { makeCrimsonsageUrl } from '@/utils/crimsonsage.js'
import { viewer } from '@/viewer.js'
import SubNav from './SubNav.vue'

const member = computed(() => viewer.value.activeMembership.member)

const memberType = computed(() => member.value?.member_type || '')

const isTerminated = computed(() => Boolean(viewer.value.activeMembership.member.member_status === 'terminated'))

const canSeeBenefitsLink = computed(
  // TODO: Hide only for third_party_admin until rules/conditions around this link are better defined
  () => memberType.value !== 'third_party_admin' && viewer.value?.activeCompany.sage_integration_status !== 'disabled'
)

const benefitsSSOLink = computed(() => makeCrimsonsageUrl(''))

const hasTimeTrackingIntegration = computed(
  () => viewer.value?.activeCompany.timetracking_integration_status === 'active'
)

const showTimeTrackingURL = computed(
  () =>
    hasTimeTrackingIntegration.value &&
    member.value?.flsa_exempt_status !== 'exempt' &&
    ['hourly', 'annual'].includes(member.value?.pay_basis)
)

const timeTrackingURL = computed(() => (showTimeTrackingURL.value ? getTimeTrackingURL() : ''))
</script>

import { computed } from 'vue'
import HeroImg from '@/assets/integrations/hero/brex-hero.webp'
import HorizontalImg from '@/assets/integrations/horizontal/brex-horizontal.svg'
import Logo from '@/assets/integrations/logo/brex-logo.svg'
import { t } from '@/i18n.js'
import IconArrowSquareOut from '~icons/ph/arrow-square-out'
import DataAndPermissionsTab from '../../../components/pdp/tabs/DataAndPermissionsTab.vue'
import OverviewTab from '../../../components/pdp/tabs/OverviewTab.vue'
import { HR_PERMISSIONS } from '../../permissions'

export const brexConfig = computed(() => ({
  marketplaceCard: {
    Logo,
    integrationName: 'Brex',
    integrationDescription: t('Automatically sync employee information from Justworks to Brex'),
    integrationCategory: t('Expense management'),
    requiredPermissions: HR_PERMISSIONS.value,
    slug: 'brex',
  },
  pdp: {
    headerDescription: t(
      'Brex offers the world’s smartest corporate cards, modern banking and treasury, and intuitive software for travel and expenses – all on one AI-powered platform.'
    ),
    HeroImg,
    HorizontalImg,
    instanceDetailsPanelConfig: {
      setupLink: {
        url: 'https://dashboard.brex.com/p/team/hris-connect',
        title: t('Get Started'),
        target: '_blank',
      },
      disconnectLink: {
        url: 'https://dashboard.brex.com/p/team/integrations',
        title: t('Disconnect Integration'),
        target: '_blank',
      },
    },
    resourceCard: {
      links: [
        {
          title: t('Brex in the Help Center'),
          url: 'https://help.justworks.com/hc/en-us/articles/29604183797787-Brex-Integration',
          Icon: IconArrowSquareOut,
          target: '_blank',
        },
        {
          title: t('Learn more about Brex'),
          url: 'https://www.brex.com/',
          Icon: IconArrowSquareOut,
          target: '_blank',
        },
      ],
    },
    infoTabsConfig: {
      0: {
        title: t('Overview'),
        Component: OverviewTab,
      },
      1: {
        title: t('Data & permissions'),
        Component: DataAndPermissionsTab,
      },
    },
    infoTabsContent: {
      overviewTab: {
        header: 'Brex + Justworks',
        subheader: t(
          'This integration seamlessly connects Justworks and Brex to automate account provisioning, deprovisioning, and employee data management in Brex.'
        ),
        list: [
          {
            summary: t('Automated account management'),
            detail: t(
              'Automatically create Brex accounts when employees are added to Justworks, and remove Brex accounts when employees are removed from Justworks.'
            ),
          },
          {
            summary: t('Daily data syncs'),
            detail: t(
              'Employee data from Justworks syncs to Brex daily, so you can effortlessly configure spend in Brex.'
            ),
          },
        ],
      },
      dataAndPermissionsTab: {
        dataSharedSection: {
          header: t('What employee information will we share with Brex?'),
          sharedDataGroups: [
            {
              header: t(
                'While the integration is connected, Brex will automatically pull in the following data from Justworks:'
              ),
              sharedData: [
                t('Email'),
                t('First Name'),
                t('Last Name'),
                t('Title'),
                t('Member Type'),
                t('Manager'),
                t('Employment Status (e.g. Active, Inactive)'),
                t('Department'),
                t('Location'),
                t('Legal Entity'),
              ],
            },
          ],
        },
        permissionsSection: {
          header: t('What permissions will I need for this integration?'),
          list: [
            {
              summary: t('In Justworks'),
              detail: t('Admins need <strong>{permissions}</strong> permissions.', {
                permissions: HR_PERMISSIONS.value.copy,
              }),
            },
            {
              summary: t('In Brex'),
              detail: t('Brex users need <b>Card admin or Account admin or User management admin</b> permissions.'),
            },
          ],
        },
      },
    },
  },
}))

import { computed } from 'vue'
import logoImg from '@/assets/integrations/logo/greenhouse-logo.svg'
import { t } from '@/i18n.js'
import { HR_PERMISSIONS } from '../../permissions'

export const greenhouseConfig = computed(() => ({
  marketplaceCard: {
    Logo: logoImg,
    integrationName: 'Greenhouse',
    integrationDescription: t('Add new hires from Greenhouse into Justworks'),
    integrationCategory: t('Hiring'),
    requiredPermissions: HR_PERMISSIONS.value,
    slug: 'greenhouse',
    getPdpLink: () => '/integrations/greenhouse',
  },
}))

import { computed } from 'vue'
import HeroImg from '@/assets/integrations/hero/electric-ai-hero.webp'
import HorizontalImg from '@/assets/integrations/horizontal/electric-ai-horizontal.svg'
import Logo from '@/assets/integrations/logo/electric-ai-logo.svg'
import { t } from '@/i18n.js'
import IconArrowSquareOut from '~icons/ph/arrow-square-out'
import DataAndPermissionsTab from '../../../components/pdp/tabs/DataAndPermissionsTab.vue'
import OverviewTab from '../../../components/pdp/tabs/OverviewTab.vue'
import { HR_PERMISSIONS } from '../../permissions'

export const electricAIConfig = computed(() => ({
  marketplaceCard: {
    Logo,
    integrationName: 'Electric AI',
    integrationDescription: t('Automatically sync employee information from Justworks to Electric AI'),
    integrationCategory: t('IT management'),
    requiredPermissions: HR_PERMISSIONS.value,
    slug: 'electric_ai',
  },
  pdp: {
    headerDescription: t('Manage your IT and security from one platform.'),
    HeroImg,
    HorizontalImg,
    instanceDetailsPanelConfig: {
      setupLink: {
        url: 'https://my.electric.ai/settings?tab=integrations',
        title: t('Get Started'),
        target: '_blank',
      },
      disconnectLink: {
        url: 'https://my.electric.ai/settings?tab=integrations',
        title: t('Disconnect Integration'),
        target: '_blank',
      },
    },
    resourceCard: {
      links: [
        {
          title: t('Electric AI in the Help Center'),
          url: 'https://help.justworks.com/hc/en-us/articles/34770289813019-Electric-AI-Integration',
          Icon: IconArrowSquareOut,
          target: '_blank',
        },
        {
          title: t('Learn more about Electric AI'),
          url: 'https://www.electric.ai/',
          Icon: IconArrowSquareOut,
          target: '_blank',
        },
      ],
    },
    infoTabsConfig: {
      0: {
        title: t('Overview'),
        Component: OverviewTab,
      },
      1: {
        title: t('Data & permissions'),
        Component: DataAndPermissionsTab,
      },
    },
    infoTabsContent: {
      overviewTab: {
        header: 'Electric AI + Justworks',
        subheader: t(
          'The Justworks x Electric integration syncs employee data and automates IT tasks across the employee lifecycle including device and software provisioning and de-provisioning.'
        ),
        list: [
          {
            summary: t('Employee data syncs'),
            detail: t('Employee data syncs from Justworks to Electric every 3 hours.'),
          },
          {
            summary: t('Automated on and offboarding'),
            detail: t(
              'When an employee is hired or removed from Justworks, Electric triggers device and software workflows.'
            ),
          },
          {
            summary: t('Automated notifications to employees'),
            detail: t('Employees are promoted to complete IT onboarding and offboarding tasks.'),
          },
          {
            summary: t('Automated notifications to admins'),
            detail: t(
              'Admins are prompted to complete IT offboarding tasks like device lock/wipe/retrieval and software de-provisioning.'
            ),
          },
        ],
      },
      dataAndPermissionsTab: {
        dataSharedSection: {
          header: t('What employee information will we share with Electric AI?'),
          sharedDataGroups: [
            {
              header: t(
                'While the integration is connected, Electric AI will automatically pull in the following data from Justworks:'
              ),
              sharedData: [
                t('First Name'),
                t('Last Name'),
                t('Work Email'),
                t('Home Email'),
                t('Phone Number'),
                t('Address'),
                t('Job Title'),
                t('Department'),
                t('Start Date'),
                t('Termination Date'),
                t('Employment status (Active/Terminated)'),
              ],
            },
          ],
        },
        permissionsSection: {
          header: t('What permissions will I need for this integration?'),
          list: [
            {
              summary: t('In Justworks'),
              detail: t('Admins need <strong>{permissions}</strong> permissions.', {
                permissions: HR_PERMISSIONS.value.copy,
              }),
            },
            {
              summary: t('In Electric AI'),
              detail: t('Electric AI users need <b>Administrator</b> permissions.'),
            },
          ],
        },
      },
    },
  },
}))

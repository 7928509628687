import { createRouter, createWebHistory } from 'vue-router'
import { setupLayouts } from 'virtual:generated-layouts'
import generatedRoutes from 'virtual:generated-pages'
import { resolvePEORedirectPath, buildPEOFlipToPath } from '@/data/peoRedirects.js'
import { featureIsOn } from '@/features.js'
import useCustomerStages from '@/use/useCustomerStages.js'
import useGlobalError from '@/use/useGlobalError.js'
import {
  getViewer,
  viewer,
  loaded,
  setActiveCompany,
  viewerCan,
  isActiveMemberTypeRestricted,
  isActiveCompanyPEO,
  isViewerOriginAllowed,
} from '@/viewer.js'
import { updateAvailable } from './check-version.js'

const { resetGlobalError } = useGlobalError()
const { showEnrollment } = useCustomerStages()

/** @type {import('vue-router').RouteRecordRaw[]} */
const redirectRoutes = [
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/company/:company_id',
    redirect: { name: 'company-company_id-settings-company-details' },
  },
  {
    path: '/company/:company_id/settings/company-taxes',
    redirect: { name: 'company-company_id-settings-compliance-and-tax-company-taxes' },
  },
]

const routes = [...setupLayouts(generatedRoutes), ...redirectRoutes]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

/** @type {import('vue-router').NavigationGuard} */
export async function beforeEach(
  to,
  _from,
  {
    viewer,
    showEnrollment,
    loaded,
    isActiveCompanyPEO,
    viewerCan,
    featureIsOn,
    setActiveCompany,
    getViewer,
    isActiveMemberTypeRestricted,
    isViewerOriginAllowed,
  }
) {
  const getStartedInShop = import.meta.env.VITE_APP_GET_STARTED_IN_SHOP === 'true'
  const shopHost = import.meta.env.VITE_APP_SHOP_HOST

  if (getStartedInShop && shopHost) {
    if (to.path === '/getstarted' || to.path.startsWith('/register')) {
      window.location.assign(`${shopHost}${to.fullPath}`)
      return false
    }
    if (to.path === '/welcome') {
      window.location.assign(`${shopHost}/register/payroll`)
      return false
    }
  }

  if (!loaded.value) {
    await getViewer()
  }

  const companyID = to.params.company_id || to.query.co
  if (companyID) setActiveCompany(companyID)

  // route auth
  const routeIsNoAuth = to.meta.noAuth
  const routeNeedsAuth = !to.meta.noAuth
  const routeNeedsStepUpAuth = to.meta.stepUpAuth

  // allow
  if (routeIsNoAuth) return

  // if viewer is undefined, treat as logged out user
  const userIsLoggedInWithOkta = !!viewer.value?.okta_id
  const userHasJWPayroll = !!viewer.value?.user_id
  const userHasCompanies = Object.keys(viewer.value?.companies || {}).length > 0
  const stepUpAcrValues = 'urn:okta:loa:1fa:pwd'
  const userHasValidStepUpAuth =
    viewer.value?.okta_acr_values == stepUpAcrValues && viewer.value.okta_auth_time > Date.now() / 1000 - 20 * 60 // okta auth time is within 20 minutes

  if (routeNeedsStepUpAuth && !userHasValidStepUpAuth) {
    const stepUpAuthParams = {
      s: to.fullPath,
      acr_values: stepUpAcrValues,
      scope: 'openid sensitiveOperation',
      max_age: 0,
    }
    const query = new URLSearchParams(stepUpAuthParams)
    const apiHost = import.meta.env.VITE_APP_API_HOST
    resetGlobalError()
    const stepUpUrl = `${apiHost}/sso/login?${query}`

    // redirecting to step up auth
    window.location.replace(stepUpUrl)
    return false
  }

  // redirect to login unless logged in to Okta or Ghost member
  if (routeNeedsAuth && !userHasJWPayroll && !userIsLoggedInWithOkta) {
    const params = { s: to.fullPath }
    const query = new URLSearchParams(params)
    const apiHost = import.meta.env.VITE_APP_API_HOST
    resetGlobalError()
    window.location.replace(`${apiHost}/sso/login?${query}`)
    return false
  }

  // route profile
  const routeIsDashboard = to.path === '/dashboard'
  const routeRequiresPermission = !!to.meta.permissions
  const routeHasMemberTypeRestrictions = !!to.meta.restrictedMemberTypes
  const feature = to.meta.feature
  const routeChecksOrigin = Boolean(to.meta.origins)

  // permissions and restrictions
  const viewerHasPermission = routeRequiresPermission && viewerCan(...to.meta.permissions)
  const memberTypeIsRestricted =
    routeHasMemberTypeRestrictions && isActiveMemberTypeRestricted(...to.meta.restrictedMemberTypes)

  // disallow
  if (!isActiveCompanyPEO.value && feature && !featureIsOn(feature)) return false

  const bypassPEORedirect = to.meta.bypassPEORedirect

  if (isActiveCompanyPEO.value && !bypassPEORedirect && (!feature || !featureIsOn(feature))) {
    const peoRedirectPath = resolvePEORedirectPath(to)
    const flipToRoute = buildPEOFlipToPath(peoRedirectPath, companyID ?? viewer.value?.activeCompany?.company_id)

    let host = import.meta.env.VITE_APP_CLOCKWORK_HOST

    const ghostType = viewer.value?.ghost_type || ''

    if (ghostType === 'cs-dash') {
      host = import.meta.env.VITE_APP_CSTOOLS_HOST
    }

    if (ghostType === 'cs-dash' && viewer.value?.read_only) {
      host = import.meta.env.VITE_APP_READONLY_CSTOOLS_HOST
    }

    window.location.assign(host + flipToRoute)
    return false
  }

  if (routeRequiresPermission && !viewerHasPermission) return false
  if (routeHasMemberTypeRestrictions && memberTypeIsRestricted) return false
  if (routeChecksOrigin && !isViewerOriginAllowed(...to.meta.origins)) return false

  // redirect
  if (userIsLoggedInWithOkta && !userHasCompanies) return '/welcome'
  if (routeIsDashboard && showEnrollment.value) return '/enroll'
}

router.beforeEach((to, from) =>
  beforeEach(to, from, {
    featureIsOn,
    getViewer,
    isActiveCompanyPEO,
    isActiveMemberTypeRestricted,
    isViewerOriginAllowed,
    loaded,
    setActiveCompany,
    showEnrollment,
    viewer,
    viewerCan,
  })
)

router.afterEach(() => {
  if (updateAvailable()) window.location.reload()
  resetGlobalError()
})

export default router

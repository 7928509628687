<template>
  <NavList :header-label="$t('Payments')" class="nav-list" :aria-label="$t('Payments')">
    <template #icon>
      <IconCurrencyCircleDollar />
    </template>
    <template #navItems>
      <PageLink :to="`/payments/payments-center`">{{ $t('Payments center') }}</PageLink>
      <PageLink
        :to="`${oneJustworksTime ? `/payments/${company_id}/timesheets` : `/payments/${company_id}/timecards`}`"
      >
        {{ $t('Timecards') }}
      </PageLink>
      <PageLink :to="`/payments/${company_id}/payrolls`">{{ $t('Payrolls') }}</PageLink>
    </template>
  </NavList>
</template>

<script setup>
import { computed } from 'vue'
import NavList from '@/components/NavList.vue'
import PageLink from '@/components/PageLink.vue'
import { featureIsOn } from '@/features.js'
import { viewer } from '@/viewer.js'
import IconCurrencyCircleDollar from '~icons/ph/currency-dollar'

const company_id = computed(() => viewer.value.activeCompany.company_id)
const oneJustworksTime = computed(() => featureIsOn('neonmoose_global_one_justworks_time'))
</script>

<style scoped>
.nav-list {
  --nav-active-background-color: var(--color-highlight-blue);
  --nav-active-border-color: var(--color-nav-blue);
  --nav-icon-color: var(--color-nav-blue);
}
</style>

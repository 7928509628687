<template>
  <div ref="usermenu-item" class="usermenu">
    <button ref="usermenu-btn" class="usermenu-btn btn-frameless" :class="{ active: show }" @click="show = !show">
      <Row style="white-space: wrap">
        <PageLink :to="`/account/settings/profile-photo`" :title="$t('Edit profile photo')">
          <MemberAvatar
            :image-url="member?.profile_picture_url"
            :first-name="member?.preferred_name || member?.first_name"
            :last-name="member?.last_name"
            size="2.5rem"
            editable
          />
        </PageLink>
        <b>{{ formatMemberName(member) || $t('User') }}</b>
      </Row>
    </button>

    <div
      ref="popover"
      :aria-hidden="!show"
      class="usermenu-popover"
      :class="{ show, top: position === 'top', bottom: position === 'bottom' }"
    >
      <WorkspaceLink :to="`/account/settings/basic-information`">
        <IconUser />
        {{ $t('Profile') }}
      </WorkspaceLink>

      <WorkspaceLink :to="`/account/settings/settings`">
        <IconGear />
        {{ $t('Settings') }}
      </WorkspaceLink>

      <Row class="usermenu-item">
        <Row gap="md">
          <IconMoon class="icon" />
          {{ $t('Dark mode') }}
        </Row>
        <FlexSpace />
        <AppearanceSwitch />
      </Row>

      <Row class="usermenu-item">
        <Row gap="md">
          <IconGlobe class="icon" />
          {{ $t('Language') }}
        </Row>
        <FlexSpace />
        <LanguageSelector style="width: 42px; height: 32px" />
      </Row>

      <WorkspaceLink :to="`/logout`">
        <IconLogout />
        {{ $t('Log out') }}
      </WorkspaceLink>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, useTemplateRef, watch } from 'vue'
import { useRoute } from 'vue-router'
import { AppearanceSwitch, FlexSpace, MemberAvatar, Row } from '@justworkshr/alma'
import { onClickOutside } from '@vueuse/core'
import LanguageSelector from '@/components/LanguageSelector.vue'
import PageLink from '@/components/PageLink.vue'
import WorkspaceLink from '@/components/WorkspaceLink.vue'
import { formatMemberName } from '@/format.js'
import IconGear from '~icons/ph/gear'
import IconGlobe from '~icons/ph/globe'
import IconMoon from '~icons/ph/moon'
import IconLogout from '~icons/ph/sign-out'
import IconUser from '~icons/ph/user'

const props = defineProps({
  member: {
    type: Object,
    default: null,
  },
  position: {
    type: String,
    default: 'top',
    validator: (value) => ['top', 'bottom'].includes(value),
  },
  offset: {
    type: Number,
    default: 0,
  },
})

const show = ref(false)

const route = useRoute()
const popover = useTemplateRef('popover')
const btn = useTemplateRef('usermenu-btn')
const item = useTemplateRef('usermenu-item')

const topOffset = computed(() => `-${(btn.value?.offsetHeight + props.offset) * 2}px`)
const bottomOffset = computed(() => `${(btn.value?.offsetHeight + props.offset) * 2}px`)

onClickOutside(
  popover,
  () => {
    show.value = false
  },
  { ignore: [btn, item] }
)

watch(
  () => route.fullPath,
  () => {
    show.value = false
  }
)
</script>

<style scoped>
.usermenu {
  position: relative;
  display: flex;
  align-items: center;
  padding-inline: 0.75rem;
  padding-block: var(--space-md);
}

.usermenu-btn {
  display: block;
  width: 100%;
  transition: background-color, outline-color;
  transition-duration: 0.5s;
  overflow: hidden;
  text-align: left;
  line-height: 1;
  border-radius: var(--radius-sm);
  outline: 1px solid transparent;
  background-color: transparent;
  padding: 0.75rem var(--space-md);

  &:hover,
  &.active {
    background-color: var(--color-nav-hover);
    outline: 1px solid var(--color-border);
  }
}

.usermenu-popover {
  background: var(--color-page-background);
  border-radius: var(--radius-sm);
  border: 1px solid var(--color-border);
  opacity: 0;
  width: 232px;
  padding: var(--space-sm) var(--space-xs);
  pointer-events: none;
  position: fixed;
  transition-property: transform, opacity, box-shadow;
  transition-duration: 0.5s;
  transition-timing-function: var(--ease-out-custom);
  box-shadow: none;
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
}

.usermenu-item {
  padding: 0.75rem var(--space-md);
  height: 2.75rem;
  white-space: nowrap;
}

.usermenu-item .icon {
  font-size: var(--text-lg);
  color: var(--color-text-secondary);
}

.usermenu-popover.show {
  opacity: 1;
  pointer-events: all;
  box-shadow: var(--color-box-shadow);
}

.usermenu-popover.show.top {
  transform: translateY(v-bind('topOffset'));
}

.usermenu-popover.show.bottom {
  transform: translateY(v-bind('bottomOffset'));
}
</style>

import { computed } from 'vue'
import logoImg from '@/assets/integrations/logo/quickbooks-logo.svg'
import { t } from '@/i18n.js'
import { ACCOUNTING_PERMISSIONS } from '../../permissions'

export const quickbooksPEOConfig = computed(() => ({
  marketplaceCard: {
    Logo: logoImg,
    integrationName: 'QuickBooks Online',
    integrationDescription: t('Easily sync your payroll and expenses with Justworks'),
    integrationCategory: t('Accounting'),
    requiredPermissions: ACCOUNTING_PERMISSIONS.value,
    slug: 'quickbooks-online',
    getPdpLink: () => '/quickbooks_online/v3/settings',
  },
}))

import { computed } from 'vue'
import { allwhereConfig } from './allwhere/config.js'
// @ts-check
import { brexConfig } from './brex/config'
import { cartaConfig } from './carta/config'
import { cultureAmpConfig } from './culture-amp/config'
import { electricAIConfig } from './electric-ai/config.js'
import { fifteenfiveConfig } from './fifteenfive/config'
import { greenhouseConfig } from './greenhouse/config'
import { jazzHrConfig } from './jazzhr/config'
import { leverConfig } from './lever/config'
import { netsuiteConfig } from './netsuite/config'
import { quickbooksPayrollConfig } from './quickbooks/payroll_config'
import { quickbooksPEOConfig } from './quickbooks/peo_config'
import { rampConfig } from './ramp/config'
import { sageIntacctConfig } from './sage-intacct/config'
import { trainualConfig } from './trainual/config'
import { vantaConfig } from './vanta/config'
import { xeroConfig } from './xero/config'

/** @type {Object<string, IntegrationConfig>} */

const clockworkConfigs = computed(() => ({
  [greenhouseConfig.value.marketplaceCard.slug]: greenhouseConfig.value,
  [netsuiteConfig.value.marketplaceCard.slug]: netsuiteConfig.value,
  [xeroConfig.value.marketplaceCard.slug]: xeroConfig.value,
  [quickbooksPEOConfig.value.marketplaceCard.slug]: quickbooksPEOConfig.value,
  [leverConfig.value.marketplaceCard.slug]: leverConfig.value,
  [jazzHrConfig.value.marketplaceCard.slug]: jazzHrConfig.value,
  [sageIntacctConfig.value.marketplaceCard.slug]: sageIntacctConfig.value,
}))

const neonConfigs = computed(() => ({
  [quickbooksPayrollConfig.value.marketplaceCard.slug]: quickbooksPayrollConfig.value,
  [rampConfig.value.marketplaceCard.slug]: rampConfig.value,
  [brexConfig.value.marketplaceCard.slug]: brexConfig.value,
  [cartaConfig.value.marketplaceCard.slug]: cartaConfig.value,
  [fifteenfiveConfig.value.marketplaceCard.slug]: fifteenfiveConfig.value,
  [vantaConfig.value.marketplaceCard.slug]: vantaConfig.value,
  [trainualConfig.value.marketplaceCard.slug]: trainualConfig.value,
  [cultureAmpConfig.value.marketplaceCard.slug]: cultureAmpConfig.value,
  [allwhereConfig.value.marketplaceCard.slug]: allwhereConfig.value,
  [electricAIConfig.value.marketplaceCard.slug]: electricAIConfig.value,
}))

export const integrationConfigs = computed(() => ({ ...clockworkConfigs.value, ...neonConfigs.value }))

/** @returns {Object<string, string>} */
export function integrationPEORouteRedirects() {
  return Object.values(clockworkConfigs.value).reduce((acc, config) => {
    const link = config.marketplaceCard.getPdpLink()
    const key = link.startsWith('/') ? link.substring(1) : link
    acc[key.replaceAll('/', '-')] = link
    return acc
  }, {})
}

/** @returns {Object<string, string[]>} */
export function integrationPEORoutePermissions() {
  return Object.values(clockworkConfigs.value).reduce((acc, config) => {
    acc[config.marketplaceCard.getPdpLink()] = config.marketplaceCard.requiredPermissions.slugs
    return acc
  }, {})
}

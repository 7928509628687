import { computed } from 'vue'
import { useGet } from '@justworkshr/use-fetch'
import { useToggle } from '@vueuse/core'

const [showWhatsNew, toggleWhatsNew] = useToggle(false)
const { data: posts, get: getWhatsNewPosts, fetching: fetchingPosts } = useGet('/data/whats-new')

export default function useWhatsNewDrawer() {
  const postNotSeenLength = computed(() => posts.value?.filter((post) => !post.seen)?.length || 0)

  return {
    fetchingPosts,
    getWhatsNewPosts,
    postNotSeenLength,
    posts,
    showWhatsNew,
    toggleWhatsNew,
  }
}

import { computed } from 'vue'
import logoImg from '@/assets/integrations/logo/lever-logo.svg'
import { t } from '@/i18n.js'
import { HR_PERMISSIONS } from '../../permissions'

export const leverConfig = computed(() => ({
  marketplaceCard: {
    Logo: logoImg,
    integrationName: 'Lever',
    integrationDescription: t('Automatically import new hires from Lever into Justworks.'),
    integrationCategory: t('Hiring'),
    requiredPermissions: HR_PERMISSIONS.value,
    slug: 'lever',
    getPdpLink: () => '/cf/integrations/lever',
  },
}))

import { computed } from 'vue'
import HeroImg from '@/assets/integrations/hero/allwhere-hero.webp'
import HorizontalImg from '@/assets/integrations/horizontal/allwhere-horizontal.svg'
import Logo from '@/assets/integrations/logo/allwhere-logo.svg'
import { t } from '@/i18n.js'
import IconArrowSquareOut from '~icons/ph/arrow-square-out'
import DataAndPermissionsTab from '../../../components/pdp/tabs/DataAndPermissionsTab.vue'
import OverviewTab from '../../../components/pdp/tabs/OverviewTab.vue'
import { HR_PERMISSIONS } from '../../permissions'

export const allwhereConfig = computed(() => ({
  marketplaceCard: {
    Logo,
    integrationName: 'allwhere',
    integrationDescription: t('Automatically sync employee information from Justworks to allwhere'),
    integrationCategory: t('IT management'),
    requiredPermissions: HR_PERMISSIONS.value,
    slug: 'allwhere',
  },
  pdp: {
    headerDescription: t(
      'One platform to manage IT assets, integrating vendors for seamless procurement, onboarding, and refreshes.'
    ),
    HeroImg,
    HorizontalImg,
    instanceDetailsPanelConfig: {
      setupLink: {
        url: 'https://www.allwhere.co/solutions/integrations/justworks',
        title: t('Get Started'),
        target: '_blank',
      },
      disconnectLink: {
        url: 'https://www.allwhere.co/solutions/integrations/justworks',
        title: t('Disconnect Integration'),
        target: '_blank',
      },
    },
    resourceCard: {
      links: [
        {
          title: t('allwhere in the Help Center'),
          url: 'https://help.justworks.com/hc/en-us/articles/34770443111451-allwhere-Integration',
          Icon: IconArrowSquareOut,
          target: '_blank',
        },
        {
          title: t('Learn more about allwhere'),
          url: 'https://www.allwhere.co/',
          Icon: IconArrowSquareOut,
          target: '_blank',
        },
      ],
    },
    infoTabsConfig: {
      0: {
        title: t('Overview'),
        Component: OverviewTab,
      },
      1: {
        title: t('Data & permissions'),
        Component: DataAndPermissionsTab,
      },
    },
    infoTabsContent: {
      overviewTab: {
        header: 'allwhere + Justworks',
        subheader: t('Connect allwhere to Justworks to save time automating manual IT processes.'),
        list: [
          {
            summary: t('Real time employee updates'),
            detail: t(
              'Sync your employee information with allwhere to keep records up to date and track employment changes like start dates, end dates, and work status. With Justworks integration, you’ll be notified when it’s time to send or retrieve equipment, keeping orders accurate and on time.'
            ),
          },
          {
            summary: t('Ship faster, with more confidence:'),
            detail: t(
              'By integrating with Justworks, allwhere can store employee addresses and emails. This allows you to ship items directly—no need for employees to fill out recipient forms—leading to faster, more accurate deliveries.'
            ),
          },
          {
            summary: t('Automated notifications to admins'),
            detail: t(
              'Admins are prompted to complete IT offboarding tasks like device lock/wipe/retrieval and software de-provisioning.'
            ),
          },
        ],
      },
      dataAndPermissionsTab: {
        dataSharedSection: {
          header: t('What employee information will we share with allwhere?'),
          sharedDataGroups: [
            {
              header: t(
                'While the integration is connected, allwhere will automatically pull in the following data from Justworks:'
              ),
              sharedData: [
                t('Work Email'),
                t('Home Email (used for all order notifications)'),
                t('First Name'),
                t('Last Name'),
                t('Preferred Name'),
                t('Start Date'),
                t('End Date'),
                t('Hire Date'),
                t('Groups'),
                t('Employment Status'),
                t('Home Address'),
                t('Work Address'),
              ],
            },
          ],
        },
        permissionsSection: {
          header: t('What permissions will I need for this integration?'),
          list: [
            {
              summary: t('In Justworks'),
              detail: t('Admins need <strong>{permissions}</strong> permissions.', {
                permissions: HR_PERMISSIONS.value.copy,
              }),
            },
            {
              summary: t('In allwhere'),
              detail: t('allwhere users need <b>Administrator</b> permissions.'),
            },
          ],
        },
      },
    },
  },
}))

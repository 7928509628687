<template>
  <div>
    <nav class="mobile-nav" :aria-label="$t('Site')">
      <div class="bar">
        <Row>
          <PageLink to="/dashboard" class="logo-j">
            <Logo height="20px" />
          </PageLink>
          <CompanySelector v-if="Object.keys(viewer.companies).length > 1" class="company-selector" />
          <PageLink v-else to="/dashboard" class="h4 company-name">
            {{ viewer.activeCompany.dba || viewer.activeCompany.entity_name }}
          </PageLink>
        </Row>

        <button class="btn-icon" @click="open = !open">
          <component :is="open ? IconX : IconMenu" font-size="var(--text-xl)" />
        </button>
      </div>
    </nav>

    <BottomSheet :open @close="open = false">
      <CurrentSubNav class="main-nav" :aria-label="$t('Main')" @route-change="closeNav" />
    </BottomSheet>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { Row } from '@justworkshr/alma'
import Logo from '@/assets/justworks-logo-j.svg'
import BottomSheet from '@/components/BottomSheet.vue'
import CompanySelector from '@/components/CompanySelector.vue'
import PageLink from '@/components/PageLink.vue'
import { viewer } from '@/viewer.js'
import IconMenu from '~icons/ph/list'
import IconX from '~icons/ph/x'
import CurrentSubNav from './CurrentSubNav.vue'

const noCloseNavRoutes = new Set([
  'account-settings-basic-information',
  'company-company_id-settings-company-details',
  'dashboard',
])

function closeNav(routeName) {
  if (noCloseNavRoutes.has(routeName)) {
    return
  }

  open.value = false
}

const open = ref(false)
</script>

<style>
:root {
  --main-nav-height: 72px;
}
</style>

<style scoped>
.mobile-nav {
  z-index: 3;
  width: 100%;
  grid-area: main-nav;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background: var(--color-nav-default);
  padding-top: var(--space-lg);
  padding-bottom: calc(var(--space-lg) + env(safe-area-inset-bottom));
  transform: translateY(0);
  transition-property: background-color, transform;
  border-top: 1px solid var(--color-border);
  box-shadow: 0 -1px 8px rgb(0 0 0 / 8%);
}

.main-nav {
  max-height: 80vh;
  overflow-y: auto;
  padding-inline: var(--space-md);
}

.mobile-nav .bar {
  padding-inline: var(--space-md);
  display: grid;
  grid-template-columns: 80% 40px;
  justify-content: space-between;
  gap: var(--space-md);
}

.logo-j {
  display: flex;
}

.company-name {
  text-decoration: none;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 1.1em; /* ensure descenders don't get cut off by the hidden overflow */
}
</style>

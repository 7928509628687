<template>
  <nav class="site-nav" :aria-label="$t('Site')">
    <Row>
      <PageLink to="/dashboard">
        <div class="logo">
          <LogoJustworks color="var(--color-white)" :height="mobile ? 40 : 16" aria-label="Justworks — Dashboard" />
        </div>
      </PageLink>

      <button v-if="mobile" class="close-icon btn-icon" @click="$emit('close')">
        <IconClose width="28" height="28" />
      </button>
    </Row>

    <div style="margin-top: 30px">
      <div v-for="group in peoNavGroups" :key="group?.key || group.groupName">
        <template v-if="group.visible">
          <NavCallout v-if="group.callout" :group />
          <NavGroup v-else :group />
        </template>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { watch } from 'vue'
import { Row } from '@justworkshr/alma'
import LogoJustworks from '@/components/LogoJustworks.vue'
import PageLink from '@/components/PageLink.vue'
import useBreakpoint from '@/use/useBreakpoint.js'
import IconClose from '~icons/ph/x'
import usePeoNavGroups from '../../use/usePeoNavGroups.js'
import NavCallout from './NavCallout.vue'
import NavGroup from './NavGroup.vue'

const emit = defineEmits(['close'])

const { mobile } = useBreakpoint()
const peoNavGroups = usePeoNavGroups()

watch(mobile, () => {
  if (mobile.value) return
  emit('close')
})
</script>

<style scoped>
.site-nav .logo {
  border-bottom: none;
  padding-bottom: 0;
}

.site-nav .close-icon {
  display: block;
  margin-left: auto;
  cursor: pointer;
  color: #5e6167;
}
</style>

import { isActiveCompanyPEO, viewer } from '@/viewer.js'

export function makeCrimsonsageUrl(path, query = {}) {
  const url = new URL(path, import.meta.env.VITE_APP_CRIMSONSAGE_HOST)

  Object.entries(query).forEach(([key, value]) => {
    if (!value) {
      return
    }
    url.searchParams.append(key, value)
  })

  url.searchParams.append('target_member_id', viewer.value.activeMembership.member.benefits_id)

  if (import.meta.env.BASE_URL === '//payroll.justworks.com' && viewer.value.ghost_type && isActiveCompanyPEO.value) {
    url.searchParams.append('sso_application', 'workforce') // Use Okta Workforce SSO.
  }

  return url.toString()
}

import { ref, watch, computed } from 'vue'
import { useGet } from '@justworkshr/use-fetch'
import { viewer, viewerCan } from '@/viewer.js'

const companyAlertCount = ref(0)

export default function useCompanyAlerts() {
  const params = computed(() => ({
    params: {
      path: {
        companyID: viewer.value.activeCompany.company_id,
      },
    },
  }))

  const {
    data: companyAlerts,
    get: getCompanyAlerts,
    fetching: fetchingCompanyAlerts,
  } = useGet('/data/companies/{companyID}/alerts', params)

  if (viewerCan('manage_paid_time_off', 'manage_employees')) {
    getCompanyAlerts()
  }

  watch(companyAlerts, (newCompanyAlerts) => {
    companyAlertCount.value = Number(newCompanyAlerts.counts?.total)
  })

  return {
    companyAlertCount,
    companyAlerts,
    fetchingCompanyAlerts,
  }
}

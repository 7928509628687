<template>
  <div style="width: 200px" class="search-container">
    <FormSearch
      v-model="search"
      class="input-small"
      :placeholder="$t('Find people...')"
      disable-error-message
      @focus="getMembersData"
      @blur="persist = false"
    />
    <template v-if="persist">
      <ul v-if="search && filteredMembers.length" class="search-box-results results">
        <li v-for="(member, index) in filteredMembers" :key="member.id" :class="{ selected: index === selectedIndex }">
          <PageLink :to="`/employee-profile/${member.id}`">
            <Row align="center" tabindex="0">
              <MemberAvatar
                :image-url="member.profile_picture_url"
                :first-name="member.preferred_name || member.first_name"
                :last-name="member.last_name"
              />
              <Stack>
                <span v-safe-html="formatMemberName(member)"></span>
              </Stack>
            </Row>
          </PageLink>
        </li>
      </ul>

      <Box v-else-if="search && !filteredMembers.length" class="search-box-results no-results">
        {{ $t('No results') }}
      </Box>
    </template>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { Row, FormSearch, MemberAvatar, Stack, Box } from '@justworkshr/alma'
import { useGet } from '@justworkshr/use-fetch'
import PageLink from '@/components/PageLink.vue'
import { formatMemberName } from '@/format.js'
import { viewer } from '@/viewer.js'

const search = ref('')
const selectedIndex = ref(-1)
const persist = ref(true)

const params = computed(() => ({
  params: {
    path: {
      companyID: viewer.value.activeCompany.company_id,
    },
  },
}))

const { data, get: getMembers } = useGet('/data/companies/{companyID}/member-search', params)

function getMembersData() {
  persist.value = true
  if (!data.value?.length) {
    getMembers()
  }
}

function countMatches(terms, attr) {
  let numMatches = 0
  terms.forEach((term) => {
    if (attr.startsWith(term)) {
      numMatches++
    }
  })
  return numMatches
}

function memberSearch() {
  if (!data.value) return []
  if (!search.value.replace(/\s/g, '').length) return data.value

  const terms = search.value.toLowerCase().split(' ')
  const nonEmptyTerms = terms.filter((term) => term.length > 0)

  return data.value.filter((member) => {
    const attributes = [member.first_name.toLowerCase().split(' '), member.last_name.toLowerCase().split(' ')].flat()

    let matchCount = 0
    attributes.forEach((attr) => {
      matchCount += countMatches(nonEmptyTerms, attr)
    })

    return matchCount >= nonEmptyTerms.length
  })
}

const filteredMembers = computed(memberSearch)

watch(filteredMembers, (newResults) => {
  selectedIndex.value = newResults.length > 0 ? 0 : -1
})
</script>

<style scoped>
.search-container {
  position: relative;
  display: inline-block;
}

.search-box-results {
  left: 0;
  position: absolute;
  background-color: var(--color-white);
  width: 200px;
  display: block;
  overflow: hidden auto;
  box-sizing: border-box;
  z-index: 1000;
  padding: 0;
}

.search-box-results.results {
  top: 60%;
}

.search-box-results.no-results {
  top: 100%;
  padding: 1rem;
  border: 1px solid var(--color-border);
  border-radius: 0;
  border-top: none;
}

.search-box-results li {
  display: block;
  margin: 0;
  padding: 14px;
  border: 0 solid var(--color-gray-300);
  border-width: 0 1px 1px;
  cursor: pointer;
  text-overflow: ellipsis;
}

.search-box-results li:hover {
  background-color: var(--color-gray-100);
}

.search-box-results li.selected {
  background-color: var(--peo-color-nav-warning);
}
</style>

<template>
  <Stack class="nav-stack">
    <Transition :name="subRouteTransitionName" mode="out-in">
      <CompanySubNav v-if="showCompanySubNav" :company_id v-bind="$attrs" />

      <YouSubNav v-else-if="showYouSubNav" v-bind="$attrs" />

      <Stack v-else gap="xs" v-bind="$attrs">
        <WorkspaceLink :to="`/dashboard`" :alert-count="companyAlertCount" name="home">
          <IconHouseLine />
          {{ $t('Home') }}
        </WorkspaceLink>

        <WorkspaceLink
          v-if="!isTerminated"
          :to="showNavManage ? `/manage/${company_id}/employees` : `/company/${company_id}/directory`"
          name="people"
        >
          <IconUsers />
          {{ $t('People') }}
        </WorkspaceLink>

        <WorkspaceLink v-if="!isTerminated" :to="`/company/calendar`" name="calendar">
          <IconCalendar />
          {{ $t('Calendar') }}
        </WorkspaceLink>

        <WorkspaceLink v-if="showNavPayments" :to="`/payments/${company_id}/payrolls`" name="pay">
          <IconInvoice />
          {{ $t('Pay') }}
        </WorkspaceLink>

        <WorkspaceLink
          v-if="showNavPayments"
          :to="`${oneJustworksTime ? `/payments/${company_id}/timesheets` : `/payments/${company_id}/timecards`}`"
          name="timecards"
        >
          <IconClock />
          {{ $t('Timecards') }}
        </WorkspaceLink>

        <WorkspaceLink :to="`/company/${company_id}/benefits/payroll`" name="benefits">
          <IconHeart />
          {{ $t('Benefits') }}
        </WorkspaceLink>

        <WorkspaceLink v-if="showNavPayments" :to="`/company/${company_id}/reports`" name="reporting">
          <IconChart />
          {{ $t('Reporting') }}
        </WorkspaceLink>

        <WorkspaceLink v-if="showNavCompany" :to="`/company/${company_id}/documents`" name="documents">
          <IconDocument />
          {{ $t('Documents') }}
        </WorkspaceLink>

        <WorkspaceLink v-if="showNavMember" caret @click="setActiveSubNav('you')">
          <IconUser />
          {{ $t('You') }}
        </WorkspaceLink>
        <WorkspaceLink v-if="showNavCompanySettings" caret @click="setActiveSubNav('company')">
          <IconGear />
          {{ $t('Company settings') }}
        </WorkspaceLink>
      </Stack>
    </Transition>

    <FlexSpace />

    <Stack gap="sm">
      <WorkspaceLink to="https://help.justworks.com/hc/en-us">
        <IconQuestion />
        {{ $t('Help') }}
      </WorkspaceLink>

      <WorkspaceLink v-if="mobile" :to="`/logout`">
        <IconSignOut />
        {{ $t('Log out') }}
      </WorkspaceLink>
    </Stack>
  </Stack>
</template>

<script setup>
import { computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { FlexSpace, Stack } from '@justworkshr/alma'
import WorkspaceLink from '@/components/WorkspaceLink.vue'
import { featureIsOn } from '@/features.js'
import useActiveSubNav from '@/use/useActiveSubNav.js'
import useBreakpoint from '@/use/useBreakpoint.js'
import useCompanyAlerts from '@/use/useCompanyAlerts.js'
import useNavPermissions from '@/use/useNavPermissions.js'
import { viewer, isTerminated } from '@/viewer.js'
import IconCalendar from '~icons/ph/calendar-blank'
import IconChart from '~icons/ph/chart-bar'
import IconClock from '~icons/ph/clock'
import IconDocument from '~icons/ph/file'
import IconGear from '~icons/ph/gear'
import IconHeart from '~icons/ph/heart'
import IconHouseLine from '~icons/ph/house-line'
import IconInvoice from '~icons/ph/invoice'
import IconQuestion from '~icons/ph/question'
import IconSignOut from '~icons/ph/sign-out'
import IconUser from '~icons/ph/user'
import IconUsers from '~icons/ph/users'
import CompanySubNav from './CompanySubNav.vue'
import YouSubNav from './YouSubNav.vue'

const emits = defineEmits(['route-change'])

const subRouteTransitionName = computed(() => (activeSubNav.value === '' ? 'slide-in-left' : 'slide-in-right'))

const { showNavPayments, showNavManage, showNavCompany, showNavMember, showNavCompanySettings } = useNavPermissions()

const { companyAlertCount } = useCompanyAlerts()

const { mobile } = useBreakpoint()
const route = useRoute()

const company_id = computed(() => viewer.value.activeCompany.company_id)

const { activeSubNav, setActiveSubNav } = useActiveSubNav()

const showCompanySubNav = computed(
  () => (activeSubNav.value === 'company' || route.meta.subnav === 'company') && !isTerminated.value
)

const showYouSubNav = computed(() => activeSubNav.value === 'you' || route.meta.subnav === 'you')

const oneJustworksTime = computed(() => featureIsOn('neonmoose_global_one_justworks_time'))

watch(
  () => route.meta.subnav,
  (newVal) => {
    if (newVal === 'company') setActiveSubNav('company')
    if (newVal === 'you') setActiveSubNav('you')
    if (!newVal) setActiveSubNav('')
  }
)

watch(
  () => route.fullPath,
  () => emits('route-change', route.name)
)
</script>

<style scoped>
.nav-stack {
  height: 80vh;
  overflow-x: hidden;

  @media (--tablet) {
    height: auto;
    min-height: 100%;
  }

  & > * {
    flex-shrink: 0;
  }
}
</style>

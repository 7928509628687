<template>
  <nav class="site-nav" :aria-label="$t('Site')">
    <div class="logo-area">
      <Row gap="md" class="logo-row">
        <PageLink to="/dashboard" class="logo-j">
          <Logo height="20px" />
        </PageLink>
        <CompanySelector v-if="Object.keys(viewer.companies).length > 1" class="company-selector" />
        <PageLink v-else to="/dashboard" class="h4 company-name">
          {{ viewer.activeCompany.dba || viewer.activeCompany.entity_name }}
        </PageLink>
      </Row>
    </div>
    <template v-if="!$route.meta.navless">
      <nav ref="nav" class="main-nav" :aria-label="$t('Main')">
        <CurrentSubNav />
      </nav>

      <div class="member-section">
        <UserMenu :offset="24" :member />
      </div>
    </template>
  </nav>
</template>

<script setup>
import { computed, ref } from 'vue'
import { Row } from '@justworkshr/alma'
import Logo from '@/assets/justworks-logo-j.svg'
import CompanySelector from '@/components/CompanySelector.vue'
import PageLink from '@/components/PageLink.vue'
import { viewer } from '@/viewer.js'
import CurrentSubNav from './CurrentSubNav.vue'
import UserMenu from './UserMenu.vue'

const nav = ref(null)
const member = computed(() => viewer.value.activeMembership && viewer.value.activeMembership.member)
</script>

<style scoped>
.site-nav {
  --height-logo: 80px;
  --height-active-company: 46px;
  --height-active-member: 212px;
  --height-member-section: 96px;

  grid-area: main-nav;
  background-color: var(--color-nav-default);
  position: sticky;
  top: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.logo-area {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: var(--height-logo);
  z-index: 1;
  padding: 0.75rem;
  background-color: var(--color-nav-translucent);
  backdrop-filter: blur(8px);
}

.logo-row {
  padding: var(--space-md);
  width: 100%;
  height: 100%;
}

.main-nav {
  position: absolute;
  inset: 0;
  padding-inline: 0.75rem;
  padding-top: var(--height-logo);
  padding-bottom: var(--height-member-section);
}

.main-nav:hover {
  overflow-y: auto;
}

.logo-j {
  display: flex;
}

.company-name {
  text-decoration: none;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 1.1em; /* ensure descenders don't get cut off by the hidden overflow */
}

.company-selector :deep(select) {
  font-weight: bold;
}

.member-section {
  position: absolute;
  z-index: 3;
  left: 0;
  bottom: 0;
  right: 0;
  margin-top: auto;
  background-color: var(--color-nav-translucent);
  backdrop-filter: blur(4px);
}
</style>

import { computed } from 'vue'
import Logo from '@/assets/integrations/logo/sage-intacct-logo.svg'
import { t } from '@/i18n.js'
import { ACCOUNTING_PERMISSIONS } from '../../permissions'

export const sageIntacctConfig = computed(() => ({
  marketplaceCard: {
    Logo,
    integrationName: 'Sage Intacct',
    integrationDescription: t('Seamlessly connect business systems for streamlined operations'),
    integrationCategory: t('Accounting'),
    requiredPermissions: ACCOUNTING_PERMISSIONS.value,
    slug: 'sage-intacct',
    getPdpLink: () => '/sage_intacct/settings',
  },
}))

import { computed } from 'vue'
import HeroImg from '@/assets/integrations/hero/trainual-hero.webp'
import HorizontalImg from '@/assets/integrations/horizontal/trainual-horizontal.svg'
import Logo from '@/assets/integrations/logo/trainual-logo.svg'
import { t } from '@/i18n.js'
import IconArrowSquareOut from '~icons/ph/arrow-square-out'
import DataAndPermissionsTab from '../../../components/pdp/tabs/DataAndPermissionsTab.vue'
import OverviewTab from '../../../components/pdp/tabs/OverviewTab.vue'
import { HR_PERMISSIONS } from '../../permissions'

export const trainualConfig = computed(() => ({
  marketplaceCard: {
    Logo,
    integrationName: 'Trainual',
    integrationDescription: t('Automatically sync employee information from Justworks to Trainual'),
    integrationCategory: t('Human Resources'),
    requiredPermissions: HR_PERMISSIONS.value,
    slug: 'trainual',
  },
  pdp: {
    headerDescription: t(
      'Trainual is an onboarding and training platform that helps businesses streamline their processes by documenting every role, responsibility, and procedure in one easy-to-use system.'
    ),
    HeroImg,
    HorizontalImg,
    instanceDetailsPanelConfig: {
      setupLink: {
        url: 'https://trainual.com/integrations/justworks',
        title: t('Get Started'),
        target: '_blank',
      },
      disconnectLink: {
        url: 'https://trainual.com/integrations/justworks',
        title: t('Disconnect Integration'),
        target: '_blank',
      },
    },
    resourceCard: {
      links: [
        {
          title: t('Trainual in the Help Center'),
          url: 'https://help.justworks.com/hc/en-us/articles/29603940566811-Trainual-Integration',
          Icon: IconArrowSquareOut,
          target: '_blank',
        },
        {
          title: t('Learn more about Trainual'),
          url: 'https://trainual.com/',
          Icon: IconArrowSquareOut,
          target: '_blank',
        },
      ],
    },
    infoTabsConfig: {
      0: {
        title: t('Overview'),
        Component: OverviewTab,
      },
      1: {
        title: t('Data & permissions'),
        Component: DataAndPermissionsTab,
      },
    },
    infoTabsContent: {
      overviewTab: {
        header: 'Trainual + Justworks',
        subheader: t(
          'Streamline employee onboarding with the Trainual + Justworks integration. Justworks automatically adds employees to your Trainual account so all you have to do is assign the content your team needs to know, and they’ll get up to speed all on their own.'
        ),
        list: [
          {
            summary: t('Faster new hire onboarding'),
            detail: t('Automatically assign your team the right training on day one.'),
          },
          {
            summary: t('Automated org chart updates'),
            detail: t(
              'Your team is constantly changing. Spend less time managing user seats with data that syncs daily from Justworks to Trainual.'
            ),
          },
        ],
      },
      dataAndPermissionsTab: {
        dataSharedSection: {
          header: t('What employee information will we share with Trainual?'),
          sharedDataGroups: [
            {
              header: t(
                'While the integration is connected, Trainual will automatically pull in the following data from Justworks:'
              ),
              sharedData: [
                t('First Name'),
                t('Last Name'),
                t('Email'),
                t('Phone Number'),
                t('Job Title'),
                t('Manager'),
              ],
            },
          ],
        },
        permissionsSection: {
          header: t('What permissions will I need for this integration?'),
          list: [
            {
              summary: t('In Justworks'),
              detail: t('Admins need <strong>{permissions}</strong> permissions.', {
                permissions: HR_PERMISSIONS.value.copy,
              }),
            },
            {
              summary: t('In Trainual'),
              detail: t('Trainual users need <b>Admin or Billing Admin</b> permissions.'),
            },
          ],
        },
      },
    },
  },
}))

import { computed } from 'vue'
import HeroImg from '@/assets/integrations/hero/vanta-hero.webp'
import HorizontalImg from '@/assets/integrations/horizontal/vanta-horizontal.svg'
import Logo from '@/assets/integrations/logo/vanta-logo.svg'
import { t } from '@/i18n.js'
import IconArrowSquareOut from '~icons/ph/arrow-square-out'
import DataAndPermissionsTab from '../../../components/pdp/tabs/DataAndPermissionsTab.vue'
import OverviewTab from '../../../components/pdp/tabs/OverviewTab.vue'
import { HR_PERMISSIONS } from '../../permissions'

export const vantaConfig = computed(() => ({
  marketplaceCard: {
    Logo,
    integrationName: 'Vanta',
    integrationDescription: t('Automatically sync employee information from Justworks to Vanta'),
    integrationCategory: t('Compliance'),
    requiredPermissions: HR_PERMISSIONS.value,
    slug: 'vanta',
  },
  pdp: {
    headerDescription: t(
      'Vanta helps thousands of fast-growing companies simplify and centralize compliance and security workflows so they can build trust.'
    ),
    HeroImg,
    HorizontalImg,
    instanceDetailsPanelConfig: {
      setupLink: {
        url: 'https://app.vanta.com/integrations',
        title: t('Get Started'),
        target: '_blank',
      },
      disconnectLink: {
        url: 'https://app.vanta.com/integrations',
        title: t('Disconnect Integration'),
        target: '_blank',
      },
    },
    resourceCard: {
      links: [
        {
          title: t('Vanta in the Help Center'),
          url: 'https://help.justworks.com/hc/en-us/articles/29603919028891-Vanta-Integration',
          Icon: IconArrowSquareOut,
          target: '_blank',
        },
        {
          title: t('Learn more about Vanta'),
          url: 'https://www.vanta.com/',
          Icon: IconArrowSquareOut,
          target: '_blank',
        },
      ],
    },
    infoTabsConfig: {
      0: {
        title: t('Overview'),
        Component: OverviewTab,
      },
      1: {
        title: t('Data & permissions'),
        Component: DataAndPermissionsTab,
      },
    },
    infoTabsContent: {
      overviewTab: {
        header: 'Vanta + Justworks',
        subheader: t(
          'Integrating Justworks and Vanta streamlines HR and compliance management by combining Justworks’ comprehensive HR solutions with Vanta’s automated security monitoring. This powerful synergy simplifies audits, ensures regulatory compliance, and enhances organizational security, reducing administrative overhead and mitigating compliance risks for businesses.'
        ),
        list: [
          {
            summary: t('Automated account management'),
            detail: t(
              'Manage employee information in Justworks, and let this integration seamlessly provision and deprovision team members to their Vanta accounts.'
            ),
          },
          {
            summary: t('Efficient data-syncing'),
            detail: t(
              'Eliminate manual work, saving time, reducing errors, and boosting overall efficiency. Data syncs from Justworks to Vanta every 24 hours, ensuring accurate, up-to-date information.'
            ),
          },
          {
            summary: t('Efficient onboarding and offboarding'),
            detail: t('Automate checks and evidence collection for employees when the join and leave your company.'),
          },
          {
            summary: t('Compliance monitoring'),
            detail: t('Automate personnel-related compliance checks.'),
          },
        ],
      },
      dataAndPermissionsTab: {
        dataSharedSection: {
          header: t('What employee information will we share with Vanta?'),
          sharedDataGroups: [
            {
              header: t(
                'While the integration is connected, Vanta will automatically pull in the following data from Justworks:'
              ),
              sharedData: [
                t('First Name'),
                t('Last Name'),
                t('Preferred Name'),
                t('Work Email'),
                t('Personal Email'),
                t('Hire Date'),
                t('Start Date'),
                t('End Date'),
                t('Employee Number'),
                t('Employment Status'),
                t('Groups'),
                t('Mobile Number'),
                t('Manager'),
              ],
            },
          ],
        },
        permissionsSection: {
          header: t('What permissions will I need for this integration?'),
          list: [
            {
              summary: t('In Justworks'),
              detail: t('Admins need <strong>{permissions}</strong> permissions.', {
                permissions: HR_PERMISSIONS.value.copy,
              }),
            },
            {
              summary: t('In Vanta'),
              detail: t('Vanta users need <b>Administrator</b> permissions.'),
            },
          ],
        },
      },
    },
  },
}))
